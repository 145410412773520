import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHelper } from 'leandredev_common-ng16/httpClient';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { BaseComponent, RunTimeDataService } from 'leandredev_common-ng16/infraService';
import { DataService } from 'src/app/services/data.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { interfaces } from '@r3group-digital/common-lib-v2';
import { filter, switchMap } from 'rxjs';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends BaseComponent implements OnInit {
  public isMobile = false;
  public mobileNavOpen = false;
  public isSignedIn = false;
  @Input() logoPartner = 'assets/img/R3-blanc.svg';
  constructor(
    public router: Router,
    protected authenticationService: AuthenticationService,
    private viewportScroller: ViewportScroller,
    protected bpObserver: BreakpointObserver,
    protected runTimeDataService: RunTimeDataService,
    protected dataService: DataService
  ) {
    super();
  }
  ngOnInit(): void {
    this.isSignedIn = this.authenticationService.isSignedIn();

    this.bpObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.XSmall]) {
        this.isMobile = true;
      }
    });
    this.subscription.add(
      this.runTimeDataService
        .getObservableStore('publicConf')
        .pipe(
          filter((data) => {
            return Boolean(data?.landingId);
          }),
          switchMap((data) => {
            return this.dataService.homeService.partner({}, data.partner).pipe(ApiHelper.resultToObjWEmptyError(`no Partner found ${data.partner}`));
          })
        )
        .subscribe({
          next: (partner: interfaces.IPartner) => {
            this.logoPartner = partner.logo?.url || 'assets/img/R3-blanc.svg';
          },
          error: (err) => {
            console.error(err);
          }
        })
    );
  }
  private gotoLogin(): void {
    void this.router.navigate(['/login/']);
  }

  public gotoAccount(): void {
    if (this.authenticationService.isSignedIn()) {
      void this.router.navigate(['/account/']);
      return;
    }
    this.gotoLogin();
  }

  about(): void {
    if (this.router.url === '/') {
      this.viewportScroller.scrollToAnchor('contact');
    } else {
      void this.router.navigate(['/'], { queryParams: { about: true } });
    }
  }

  public gotoUserCompanies(): void {
    if (this.authenticationService.isSignedIn()) {
      void this.router.navigate(['/company/']);
      return;
    }
    this.gotoLogin();
  }

  public logOut(): void {
    this.authenticationService.signOut();
  }
}
