import { Component, OnInit } from '@angular/core';

import { VisualComponentComponent } from '../visual-component.component';
import { interfaces } from '@r3group-digital/common-lib-v2';

@Component({
  selector: 'app-titre-sous-titre',
  templateUrl: './titre-sous-titre.component.html',
  styleUrls: ['./titre-sous-titre.component.scss']
})
export class TitreSousTitreComponent extends VisualComponentComponent<interfaces.ITitreSousTitre> implements OnInit {
  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
