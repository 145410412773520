import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { AppVersion } from './AppVersion';
import { PublicConfService } from './services/public-conf.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  protected timeoutId!: number;
  title = 'r3-app';
  constructor(
    protected router: Router,
    protected publicConfService: PublicConfService,
    protected authenticationService: AuthenticationService
  ) {
    console.info(`%c Version: ${AppVersion.appVersion}`, 'background:#ff0000;color:#FFFFFF;font-size:50px');
  }
  ngOnDestroy(): void {
    window.clearTimeout(this.timeoutId);
  }

  activityWhatcher(): void {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => {
      this.closeConnection();
    }, 1800000);
  }

  protected closeConnection(): void {
    if (this.authenticationService.isSignedIn()) {
      this.authenticationService.signOut();
      void this.router.navigate(['/']);
    }
  }
}
