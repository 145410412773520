import { ApiHelper } from 'leandredev_common-ng16/httpClient';
import { map, switchMap } from 'node_modules/rxjs/operators';

import { Injectable } from '@angular/core';
import { Observable } from 'node_modules/rxjs';
import { interfaces } from '@r3group-digital/common-lib-v2';

import { Authentication } from '@r3group-digital/ng16-lib-v2/httpClient/services/authentication';

import { SignInJwtService } from './sign-in-jwt.service';
import { HttpClient } from '@angular/common/http';
import { BaseComponent, RunTimeDataService } from 'leandredev_common-ng16/infraService';

@Injectable()
export class AuthenticationService extends BaseComponent {
  public api: Authentication;

  constructor(
    protected confService: SignInJwtService,
    protected runTimeDataService: RunTimeDataService,
    protected http: HttpClient
  ) {
    super();
    this.api = new Authentication(this.http, false);

    this.subscription.add(
      this.runTimeDataService.getObservableStore('publicConf').subscribe({
        next: (data: { r3api: string }) => {
          if (data) {
            this.api.urlCollection = `${data.r3api}authentication/`;
          }
        }
      })
    );
  }

  public signUp(userInfo: interfaces.ICreateProfileRequest): Observable<any> {
    return this.api.signUp(userInfo, {}).pipe(
      ApiHelper.resultToObjWEmptyError('Error lors de la création de compte'),
      map(() => {
        return this.signIn({ login: userInfo.email, password: userInfo.password } as interfaces.ISigninRequest);
      })
    );
  }

  public signInByLogin(email: string, password: string): Observable<any> {
    const siginRequest: interfaces.ISigninRequest = { login: email, password };
    return this.signIn(siginRequest);
  }

  private signIn(signinRequest: interfaces.ISigninRequest): Observable<any> {
    return this.api.signIn(signinRequest, {}).pipe(
      ApiHelper.resultToObjWEmptyError('Error when sign in'),
      switchMap((res) => {
        return this.confService.connect(res.token);
      })
    );
  }

  public signOut(): Observable<boolean> {
    return this.confService.disconnect();
  }

  public isSignedIn(): boolean {
    return this.confService.isConnected;
  }

  public lostPassword(email: string, url: string): Observable<any> {
    return this.api.lostPassword({ email, redirectUrl: url }, {});
  }

  public reserPassword(resetPasswordRequest: interfaces.IresetPasswordRequest): Observable<any> {
    return this.api.resetPassword(resetPasswordRequest, {}).pipe(ApiHelper.resultToObjWEmptyError('Erreur de mise a jour du mot de passe'));
  }
}
