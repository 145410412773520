<div *ngIf="data?.icon !== 'none'" class="feature feature-6">
  <div class="icon-container">
    <mat-icon [inline]="true" color="primary">{{ data?.icon }}</mat-icon>
  </div>

  <h4 *ngIf="data?.name">{{ data?.name }}</h4>
  <p *ngIf="data?.description">{{ data?.description }}</p>
</div>

<div *ngIf="data?.icon === 'none'" class="feature feature-6 green-block">
  <h4>{{ data?.name }}</h4>
  <p>{{ data?.description }}</p>
</div>
