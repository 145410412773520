import { interfaces } from '@r3group-digital/common-lib-v2';
import { DataService } from './data.service';
import { ApiHelper } from 'leandredev_common-ng16/httpClient';
import { Home } from '@r3group-digital/ng16-lib-v2/httpClient/services/home';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AppHomeService {
  public homeService: Home;

  constructor(private dataService: DataService) {
    this.homeService = this.dataService.homeService;
  }

  public contact(contactRequest: interfaces.IContactRequest): Observable<interfaces.IContactResponse> {
    return this.homeService.contact(contactRequest, {}).pipe(ApiHelper.resultToObjWEmptyError('Erreur d envoie du message'));
  }

  public contactPartner(contactRequest: interfaces.IContactRequest): Observable<interfaces.IContactResponse> {
    return this.homeService.contactPartner(contactRequest, {}).pipe(ApiHelper.resultToObjWEmptyError('Erreur d envoie du message'));
  }
}
