import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './services/authentication.service';
import { DataService } from './services/data.service';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';

import { ShellModule } from './shell/shell.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfraServiceModule } from 'leandredev_common-ng16/infraService';

import { environment } from 'src/environments/environment';
import { AppHomeService } from './services/apphome.service';

@NgModule({
  declarations: [AppComponent],
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    InfraServiceModule.forRoot(environment),
    NgbModule,
    MatDialogModule,
    ShellModule,
    AppRoutingModule
  ],
  exports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    InfraServiceModule,
    MatDialogModule,
    RouterModule
  ],
  providers: [DataService, AuthenticationService, AppHomeService, { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }],
  bootstrap: [AppComponent]
})
export class AppModule {}
