import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RunTimeDataService } from 'leandredev_common-ng16/infraService';
import { Home } from '@r3group-digital/ng16-lib-v2/httpClient/services/home';
import { UserProfile } from '@r3group-digital/ng16-lib-v2/httpClient/services/userProfile';
import { MyCompany } from '@r3group-digital/ng16-lib-v2/httpClient/services/myCompany';
import { Authentication } from '@r3group-digital/ng16-lib-v2/httpClient/services/authentication';
import { CompanyEntity } from '@r3group-digital/ng16-lib-v2/httpClient/services/companyEntity';
import { SummarySurvey } from '@r3group-digital/ng16-lib-v2/httpClient/services/SummarySurvey';
import { SectorSurvey } from '@r3group-digital/ng16-lib-v2/httpClient/services/sectorSurvey';
import { Content } from '@r3group-digital/ng16-lib-v2/httpClient/services/content';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  public homeService: Home;
  public userProfileService: UserProfile;
  public authService: Authentication;
  public myCompanyService: MyCompany;
  public entityService!: CompanyEntity;
  public surveyService: SummarySurvey;
  public sectorSurveyService: SectorSurvey;
  public contentService: Content;
  constructor(
    private http: HttpClient,
    private runTimeDataService: RunTimeDataService
  ) {
    this.homeService = new Home(this.http, false);
    this.userProfileService = new UserProfile(this.http, true);
    this.authService = new Authentication(this.http, false);
    this.myCompanyService = new MyCompany(this.http, true);
    this.entityService = new CompanyEntity(this.http, true);
    this.surveyService = new SummarySurvey(this.http, true);
    this.sectorSurveyService = new SectorSurvey(this.http, true);
    this.contentService = new Content(this.http, false);

    this.runTimeDataService.getObservableStore('publicConf').subscribe((data: any) => {
      if (data && data.r3api) {
        this.myCompanyService.urlCollection = `${data.r3api}my-company/`;
        this.surveyService.urlCollection = `${data.r3api}summary-survey/`;
        this.sectorSurveyService.urlCollection = `${data.r3api}sector-survey/`;
        this.userProfileService.urlCollection = `${data.r3api}profile/`;
        this.contentService.urlCollection = `${data.r3api}content/`;
        this.entityService.urlCollection = `${data.r3api}companyEntity/`;
        this.homeService.urlCollection = `${data.r3api}home/`;
      }
    });
  }
}
