<section class="cover height-80 imagebg text-center" data-overlay="4">
  <div class="background-image-holder" [ngStyle]="{ 'background-image': 'url(' + (data?.img?.url | fsoImageOptimizer) + ')', opacity: 1 }">
    <img alt="background" [src]="data?.img?.url | fsoImageOptimizer" />
  </div>
  <div class="container pos-vertical-center">
    <div class="row">
      <div class="col-md-8 col-lg-12">
        <h1 class="blaak">{{ data?.name }}</h1>
        <p class="lead">{{ data?.subtitle }}</p>
        <button class="btn btn-primary type--uppercase" (click)="navigate()">
          <span class="btn__text"> {{ data?.btnLabel }} </span>
        </button>
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</section>
