<section class=" ">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <ng-container #container1></ng-container>

        <!--end feature-->
      </div>
      <div class="col-md-6 col-lg-4">
        <ng-container #container2></ng-container>

        <!--end feature-->
      </div>
      <div class="col-md-6 col-lg-4">
        <ng-container #container3></ng-container>

        <!--end feature-->
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</section>
