import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { VisualComponentComponent } from '../visual-component.component';
import { interfaces } from '@r3group-digital/common-lib-v2';

@Component({
  selector: 'app-header-banner',
  templateUrl: './header-banner.component.html',
  styleUrls: ['./header-banner.component.scss']
})
export class HeaderBannerComponent extends VisualComponentComponent<interfaces.IHeaderBanner> implements OnInit {
  constructor(private router: Router) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  navigate(): void {
    if (this.data?.btnLink) {
      const linkArr: string[] = this.data.btnLink.split('/');
      if (linkArr.length == 0) {
        linkArr.push('/');
      }
      void this.router.navigate(linkArr);
    }
  }
}
