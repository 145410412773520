import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'leandredev_common-ng16/infraService';

@Component({
  template: ''
})
export class VisualComponentComponent<T> extends BaseComponent implements OnInit {
  protected pData: T | null = null;

  @Input()
  public get data(): T | null {
    return this.pData;
  }
  public set data(value: T | null) {
    if (value != this.pData) {
      this.pData = value;
      this.dataUpdated();
    }
  }

  constructor() {
    super();
  }

  dataUpdated(): void {
    //tod
  }

  ngOnInit(): void {
    // empty
    return;
  }
}
