// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // ssoIssuer: 'https://api.justplug.fr',
  // ssoUrl: 'https://api.justplug.fr/.well-known/openid-configuration',
  // ssoclientId: 'hfdev',

  // configurationUrl: 'https://api.justplug.fr/licencesService/',
  application_instance: '613f5819c037f18109fdebf2',
  // end_client: '613f56c62381b63d96637864',
  // scope: 'openid profile email phone',

  data: {
    r3api: '/api/',
    // r3api: 'https://api.justplug.fr/api-r3/'
    isR3: false,
    partner: '625027e670ed321d6f710aa6',
    authServiceUrl: '/api/authentication/'
  },
  urlBase: 'https://r3.justplug.info',
  inv: '',
  userMail: ''
};
