import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { interfaces } from '@r3group-digital/common-lib-v2';

import { map } from 'node_modules/rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { VisualComponentComponent } from '../visual-component.component';
import { MessageSendedComponent } from './message-sended/message-sended.component';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { ApiHelper } from 'leandredev_common-ng16/httpClient';
import { DataService } from 'src/app/services/data.service';
import { AppHomeService } from 'src/app/services/apphome.service';
import { UserProfile } from '@r3group-digital/ng16-lib-v2/httpClient/services/userProfile/userProfile';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends VisualComponentComponent<interfaces.IContact> implements OnInit, AfterViewInit {
  private userProfileService: UserProfile;
  mailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  public form!: FormGroup;
  public mailSended = false;

  constructor(
    private dialog: MatDialog,
    protected homeService: AppHomeService,
    private dataService: DataService,
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected viewport: ViewportScroller
  ) {
    super();
    this.userProfileService = this.dataService.userProfileService;
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      contactName: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.userProfileService
      .getProfile({})
      .pipe(
        ApiHelper.resultToObjWEmptyError('Erreur dans la recuperations de information de l utilisateur'),
        map((profile: interfaces.IUserProfile) => {
          if (profile) {
            this.form.patchValue({ contactName: profile.name, email: profile.user_pop?.email });
          }
        })
      )
      .subscribe();
  }

  public sendContact(): any {
    if (!this.form.valid) {
      return;
    }
    const contactRequest: interfaces.IContactRequest = {
      userMail: this.form.get('email')?.value,
      contactName: this.form.get('contactName')?.value,
      userMessage: this.form.get('message')?.value,
      partnerMail: this.data?.email
    };
    this.homeService.contactPartner(contactRequest).subscribe(() => {
      this.dialog
        .open(MessageSendedComponent)
        .afterClosed()
        .subscribe(() => {
          this.form.patchValue({ userMail: '', contactName: '', userMessage: '', partnerMail: '' });
          this.viewport.scrollToAnchor('start');
        });
    });
  }
}
