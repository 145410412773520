import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { interfaces } from '@r3group-digital/common-lib-v2';

import { VisualDynamicComponentComponent } from '../visual-dynamic-component.component';
import { DeserializerService } from 'src/app/services/deserializer.service';

@Component({
  selector: 'app-uimea2',
  templateUrl: './uimea2.component.html',
  styleUrls: ['./uimea2.component.scss']
})
export class UIMEA2Component extends VisualDynamicComponentComponent<interfaces.IUIMEA2> implements OnInit {
  @ViewChild('container2', { static: false, read: ViewContainerRef }) container2!: ViewContainerRef;

  override internalDeserialise(): void {
    if (this.container1) {
      if (this.data?.bloc1) {
        this.deserialiser.clearComponentAndDeserialise(this.container1, this.data.bloc1);
      } else {
        this.deserialiser.clearComponent(this.container1);
      }
    } else {
      // console.warn('no block1');
    }
    if (this.container2) {
      if (this.data?.bloc2) {
        this.deserialiser.clearComponentAndDeserialise(this.container2, this.data.bloc2);
      } else {
        this.deserialiser.clearComponent(this.container2);
      }
    } else {
      // console.warn('no block1');
    }
  }

  constructor(protected deserialiser: DeserializerService) {
    super(deserialiser);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
