<footer class="text-center space--sm footer-5 bg--dark">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-block">
          <ul class="list-inline list--hover">
            <li>
              <a [href]="titleUrl" target="_blank">
                <span>{{ title }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul class="social-list list-inline list--hover">
            <li>
              <a [href]="linkedin" target="_blank">
                <i class="socicon socicon-linkedin icon icon--xs"></i>
              </a>
            </li>
            <li>
              <a [href]="instagram" target="_blank">
                <i class="socicon socicon-instagram icon icon--xs"></i>
              </a>
            </li>
            <li>
              <a [href]="facebook">
                <i class="socicon socicon-facebook icon icon--xs"></i>
              </a>
            </li>
            <li>
              <a [href]="youtube" target="_blank">
                <i class="socicon socicon-youtube icon icon--xs"></i>
              </a>
            </li>
            <li>
              <a [href]="twitter" target="_blank">
                <i class="socicon socicon-twitter icon icon--xs"></i>
              </a>
            </li>
          </ul>
        </div>

        <div>
          <span class="type--fine-print">&copy; <span class="update-year"></span> Stack Inc.</span>
          <a class="type--fine-print" [routerLink]="['page', cgu]">Conditions Générales</a>
          <a class="type--fine-print" [routerLink]="['page', legalNotice]">Mentions Légales</a>
        </div>
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</footer>

<!--<div class="loader"></div>-->
<a class="back-to-top inner-link" href="#start" data-scroll-class="100vh:active">
  <i class="stack-interface stack-up-open-big"></i>
</a>
