import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { interfaces } from '@r3group-digital/common-lib-v2';

import { VisualDynamicComponentComponent } from '../visual-dynamic-component.component';
import { DeserializerService } from 'src/app/services/deserializer.service';

@Component({
  selector: 'app-mea3',
  templateUrl: './mea3.component.html',
  styleUrls: ['./mea3.component.scss']
})
export class MEA3Component extends VisualDynamicComponentComponent<interfaces.IMEA3> implements OnInit {
  @ViewChild('container2', { static: false, read: ViewContainerRef }) container2!: ViewContainerRef;
  @ViewChild('container3', { static: false, read: ViewContainerRef }) container3!: ViewContainerRef;

  override internalDeserialise(): void {
    if (this.container1) {
      if (this.data?.bloc1) {
        this.deserialiser.clearComponentAndDeserialise(this.container1, this.data.bloc1);
      } else {
        this.deserialiser.clearComponent(this.container1);
      }
    } else {
      console.warn('no block1');
    }
    if (this.container2) {
      if (this.data?.bloc2) {
        this.deserialiser.clearComponentAndDeserialise(this.container2, this.data.bloc2);
      } else {
        this.deserialiser.clearComponent(this.container2);
      }
    } else {
      console.warn('no block1');
    }
    if (this.container3) {
      if (this.data?.bloc3) {
        this.deserialiser.clearComponentAndDeserialise(this.container3, this.data.bloc3);
      } else {
        this.deserialiser.clearComponent(this.container3);
      }
    } else {
      console.warn('no block1');
    }
  }
  constructor(protected deserialiser: DeserializerService) {
    super(deserialiser);
  }
  override ngOnInit(): void {
    super.ngOnInit();
  }
}
