import { Component, OnInit } from '@angular/core';

import { VisualComponentComponent } from '../visual-component.component';
import { interfaces } from '@r3group-digital/common-lib-v2';

@Component({
  selector: 'app-mea-block-text',
  templateUrl: './mea-block-text.component.html',
  styleUrls: ['./mea-block-text.component.scss']
})
export class MEABlockTextComponent extends VisualComponentComponent<interfaces.IMEA_Block_Text> implements OnInit {
  public numberValue = 0;
  public isNumberValue = false;
  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override dataUpdated(): void {
    if (isNaN(Number(this.data?.indice)) || Math.round(Number(this.data?.indice)) < 0 || Math.round(Number(this.data?.indice)) > 100) {
      this.isNumberValue = false;
      if (this.data?.indice && this.data?.indice.slice(-1) === '%' && this.data?.indice.indexOf('%') === this.data?.indice.length - 1) {
        const str = this.data?.indice.slice(0, -1);
        this.numberValue = Math.round(Number(str));
        this.isNumberValue = true;
      }
    } else {
      this.numberValue = Math.round(Number(this.data?.indice));
      this.isNumberValue = true;
    }
  }
}
