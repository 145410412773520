<div class="col-md-6 col-lg-6">
  <ng-container #container1></ng-container>

  <!--end feature-->
</div>
<div class="col-md-6 col-lg-6">
  <ng-container #container2></ng-container>

  <!--end feature-->
</div>

<!--end of container-->
