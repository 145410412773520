import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fsoImageOptimizer'
})
export class FsoImageOptimizerPipe implements PipeTransform {
  transform(value: any, width = 1024, height = 0, compress = 100): string {
    if (String(value).endsWith('/')) {
      return `${value}webp/${width}/${height}/${compress}`;
    }
    return `${value}/webp/${width}/${height}/${compress}`;
  }
}
