<section class="switchable switchable--switch">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-md-5">
        <img alt="Image" class="border--round box-shadow-wide" [src]="data?.img?.url | fsoImageOptimizer" />
      </div>
      <div class="col-md-6">
        <div class="row mx-0 switchable__text flex-column">
          <p class="lead">
            E:
            <a href="mailto:{{ data?.email }}?subject=Mail de contact">{{ data?.email }}</a>
            <br />
            P: {{ data?.phone }}
          </p>
          <p class="lead">{{ data?.description }}</p>
          <!-- <p class="lead">We are open from 9am &mdash; 5pm week days.</p> -->
          <hr class="short" />
          <form class="form-email row" [formGroup]="form">
            <div class="col-md-6">
              <label>Nom Prénom</label>
              <input type="text" name="contactName" placeholder="Nom Prénom" formControlName="contactName" required />
              <mat-error *ngIf="form.controls.contactName.invalid && (form.controls.contactName.dirty || form.controls.contactName.touched)"
                >Champ obligatoire
              </mat-error>
            </div>
            <div class="col-md-6">
              <label>Email :</label>
              <input type="text" name="email" placeholder="Adresse Mail" formControlName="email" required [pattern]="mailPattern" />
              <mat-error *ngIf="form.controls.email.invalid && (form.controls.email.dirty || form.controls.email.touched)" i18n
                >Champ obligatoire</mat-error
              >
              <mat-error *ngIf="form.controls.email.errors?.pattern" i18n>Adresse email non valide</mat-error>
            </div>
            <div class="col-md-12">
              <label>Message:</label>
              <textarea type="textarea" rows="5" name="message" placeholder="Message" formControlName="message" required></textarea>
              <mat-error *ngIf="form.controls.message.invalid && (form.controls.message.dirty || form.controls.message.touched)" i18n
                >Champ obligatoire
              </mat-error>
            </div>
            <div class="col-md-6 col-lg-6">
              <a class="btn btn-primary lg type--uppercase" style="cursor: pointer" (click)="sendContact()" i18n>
                <span>Envoyer</span>
              </a>
            </div>
          </form>
        </div>
        <!--end of row-->
      </div>
    </div>
    <!--end of row-->
  </div>
  <!--end of container-->
</section>
