import { Component, OnInit } from '@angular/core';

import { VisualComponentComponent } from '../visual-component.component';
import { interfaces } from '@r3group-digital/common-lib-v2';

@Component({
  selector: 'app-uititle-subtitle',
  templateUrl: './uititle-subtitle.component.html',
  styleUrls: ['./uititle-subtitle.component.scss']
})
export class UITitleSubtitleComponent extends VisualComponentComponent<interfaces.IUITitleSubtitle> implements OnInit {
  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
