import { AfterViewInit, Component, OnInit } from '@angular/core';
import { interfaces } from '@r3group-digital/common-lib-v2';

import { VisualDynamicComponentComponent } from '../visual-dynamic-component.component';
import { DeserializerService } from 'src/app/services/deserializer.service';

@Component({
  selector: 'app-img-side-by-side',
  templateUrl: './img-side-by-side.component.html',
  styleUrls: ['./img-side-by-side.component.scss']
})
export class ImgSideBySideComponent extends VisualDynamicComponentComponent<interfaces.IImgSideBySide> implements OnInit, AfterViewInit {
  override internalDeserialise(): void {
    if (this.data?.content) {
      this.deserialiser.clearComponentAndDeserialiseArr(this.container1, this.data?.content);
    } else {
      this.deserialiser.clearComponent(this.container1);
    }
  }

  constructor(protected deserialiser: DeserializerService) {
    super(deserialiser);
  }
  override ngOnInit(): void {
    super.ngOnInit();
  }
}
