import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { DeserializerService } from '../../services/deserializer.service';
import { VisualComponentComponent } from './visual-component.component';

@Component({
  template: ''
})
export class VisualDynamicComponentComponent<T>
  extends VisualComponentComponent<T>
  implements OnInit, AfterViewInit, AfterViewChecked, AfterContentInit
{
  protected viewIsOk = false;
  protected hasDeserialiseData = false;
  @ViewChild('container1', { static: false, read: ViewContainerRef })
  container1!: ViewContainerRef;

  @Input()
  public override get data(): T | null {
    return this.pData;
  }
  public override set data(value: T | null) {
    if (this.pData == value) {
      return;
    }
    this.pData = value;
    if (this.pData) {
      this.hasDeserialiseData = true;
    }
    this.deserialize();
  }

  constructor(protected deserializerService: DeserializerService) {
    super();
  }

  ngAfterContentInit(): void {
    // this.viewIsOk = true;
    // this.deserialize();
  }

  ngAfterViewChecked(): void {
    // this.viewIsOk = true;
    // this.deserialize();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.viewIsOk = true;
    this.deserialize();
  }

  public deserialize(): void {
    if (this.viewIsOk && this.hasDeserialiseData) {
      this.internalDeserialise();
    }
  }

  public internalDeserialise(): void {
    throw new Error('internalDeserialise must be implemented');
  }
}
