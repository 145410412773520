<section class="imageblock switchable feature-large" [class.switchable--switch]="data?.ltr === false">
  <div class="imageblock__content col-lg-4 col-md-3 pos-right">
    <div class="background-image-holder" [ngStyle]="{ 'background-image': 'url(' + (data?.img?.url | fsoImageOptimizer) + ')', opacity: 1 }">
      <img alt="image" [src]="data?.img?.url | fsoImageOptimizer" />
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-8">
        <ng-container #container1></ng-container>
      </div>
    </div>

    <!--end of row-->
  </div>
  <!--end of container-->
</section>
