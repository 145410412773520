<div class="nav-container">
  <nav *ngIf="!isMobile" class="bar bar-4 bar--transparent bar--absolute" data-fixed-at="200">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-2 col-md-offset-0 col-4">
          <div class="bar__module">
            <a href="index.html">
              <div fxLayout="row wrap">
                <img class="logo logo-light" *ngIf="!logoPartner" alt="logo" src="assets/img/R3-blanc.svg" />
                <img class="logo logo-light partner" alt="logo" [src]="logoPartner" />
              </div>
            </a>
          </div>
          <!--end module-->
        </div>

        <div class="col-lg-8 col-md-12 text-right text-left-xs text-left-sm">
          <div class="bar__module">
            <ul class="menu-horizontal text-left">
              <li class="dropdown">
                <a class="menu-link" [class.grey-link]="router.url.includes('login')" href="https://www.r3-group.fr/" target="_blank"
                  >Qui sommes-nous ?</a
                >
              </li>
              <li class="dropdown">
                <a
                  class="menu-link"
                  [class.grey-link]="router.url.includes('login')"
                  href="https://www.linkedin.com/company/r3-impact-france/"
                  target="_blank"
                  >News</a
                >
              </li>

              <li class="btn type--uppercase sand" href="#" (click)="gotoUserCompanies()" i18n>
                <span> Mon diagnostic </span>
              </li>
              <li class="btn btn-primary type--uppercase" href="#" (click)="gotoAccount()" i18n>
                <span> Mon Compte </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!--end nav desktop-->
  <nav *ngIf="isMobile">
    <div class="container-mobile">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
        <a routerLink="/">
          <img class="logo-mobile" alt="logo" src="assets/img/logo.webp" />
        </a>
        <a routerLink="/">
          <img class="logo-mobile" alt="logo" *ngIf="!logoPartner" [src]="logoPartner" />
        </a>
        <button mat-fab (click)="mobileNavOpen = !mobileNavOpen">
          <mat-icon>menu</mat-icon>
        </button>
      </div>

      <ul *ngIf="mobileNavOpen" class="text-left">
        <li class="dropdown">
          <a class="menu-link" href="https://www.r3-group.fr/" target="_blank">Qui sommes-nous ?</a>
        </li>
        <li class="dropdown">
          <a class="menu-link" href="https://www.linkedin.com/company/r3-impact-france/" target="_blank" i18n>News</a>
        </li>

        <li class="btn type--uppercase sand" href="#" (click)="gotoUserCompanies()" i18n>
          <span> Mon diagnostic </span>
        </li>
        <li class="btn btn-primary type--uppercase" href="#" (click)="gotoAccount()" i18n>
          <span> Mon Compte </span>
        </li>
      </ul>
    </div>
  </nav>
</div>
