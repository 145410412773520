import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeserializerService } from '../services/deserializer.service';
import { RunTimeDataService } from 'leandredev_common-ng16/infraService';

@NgModule({
  declarations: [NavigationComponent, FooterComponent],
  imports: [CommonModule, RouterModule, NgbModule, MatIconModule, MatButtonModule],
  exports: [NavigationComponent, FooterComponent],
  providers: [DeserializerService, RunTimeDataService]
})
export class ShellModule {}
