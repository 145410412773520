import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsSignedInGuard } from './guards/is-signed.guard';
import { HttpClientModule } from '@angular/common/http';
const windowPerso: Window & typeof globalThis & { daesignConfig?: any; originalUrl?: string } = window;
const routes: Routes = [
  {
    path: 'deconnected',
    loadChildren: () => import('./modules/authentication/authentication.module').then((module) => module.AuthenticationModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/authentication/authentication.module').then((module) => module.AuthenticationModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./modules/my-company/my-company.module').then((module) => module.MyCompanyModule),
    canActivate: [IsSignedInGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/user-profile/user-profile.module').then((module) => module.UserProfileModule),
    canActivate: [IsSignedInGuard]
  },
  {
    path: 'page',
    loadChildren: () => import('./modules/basic-pages/basic-pages.module').then((m) => m.BasicPagesModule)
  }
];

if (windowPerso && windowPerso.daesignConfig && windowPerso.daesignConfig.data && !windowPerso.daesignConfig.data.isR3) {
  routes.push({
    path: '',
    loadChildren: () => import('./modules/landing/landing.module').then((module) => module.LandingModule)
  });
} else {
  routes.push({
    path: '',
    loadChildren: () => import('./modules/home/home.module').then((module) => module.HomeModule)
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes), HttpClientModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
