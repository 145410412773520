import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message-sended',
  templateUrl: './message-sended.component.html',
  styleUrls: ['./message-sended.component.scss']
})
export class MessageSendedComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<MessageSendedComponent>
  ) {}

  closeComponentWindow(): void {
    this.dialogRef.close();
  }
}
