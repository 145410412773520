<!-- <div class="col-md-6 col-lg-6">
  <ng-container #container1></ng-container>
</div>
<div class="col-md-6 col-lg-6">
  <ng-container #container2></ng-container>
</div> -->

<!--end of container-->

<div style="flex-flow: wrap; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center" fxLayout.lt-sm="column">
  <div style="margin-right: 20px; flex: 1 1 calc(50% - 20px); box-sizing: border-box; min-width: calc(50% - 20px)">
    <ng-container #container1></ng-container>
  </div>
  <div style="flex: 1 1 calc(50% - 20px); box-sizing: border-box; min-width: calc(50% - 20px)">
    <ng-container #container2></ng-container>
  </div>
</div>
