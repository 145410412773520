<div class="cta-container">
  <h2 class="blaak primary">{{ data?.name }}</h2>
  <p [innerHtml]="data?.description"></p>
  <div
    style="flex-flow: wrap; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center"
    fxLayout.lt-sm="column"
  >
    <div
      style="flex-basis: calc(50% - 20px); box-sizing: border-box; max-width: calc(50% - 20px); min-width: calc(50% - 20px)"
      *ngFor="let button of data?.buttons"
      class="elt"
    >
      <button
        class="btn btn-secondary type--uppercase"
        (click)="callAction(button.url)"
        style="flex-direction: column; box-sizing: border-box; display: flex; place-content: center flex-start; align-items: center"
      >
        <mat-icon *ngIf="button.icon && button.icon !== ''" [inline]="true">{{ button.icon }}</mat-icon>
        <p>{{ button.label }}</p>
      </button>
    </div>
  </div>
</div>
