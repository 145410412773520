import { Component, OnInit } from '@angular/core';
import { interfaces } from '@r3group-digital/common-lib-v2';

import { VisualComponentComponent } from '../visual-component.component';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss']
})
export class CallToActionComponent extends VisualComponentComponent<interfaces.ICallToAction> implements OnInit {
  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  callAction(url: string): void {
    window.open(url, '_blank');
  }
}
