import { Injectable, ViewContainerRef } from '@angular/core';

import { CallToActionComponent } from '../modules/cms/call-to-action/call-to-action.component';
import { ContactComponent } from '../modules/cms/contact/contact.component';
import { HeaderBannerComponent } from '../modules/cms/header-banner/header-banner.component';
import { ImgSideBySideComponent } from '../modules/cms/img-side-by-side/img-side-by-side.component';
import { MEA2Component } from '../modules/cms/mea2/mea2.component';
import { MEA3Component } from '../modules/cms/mea3/mea3.component';
import { MEABlockIconComponent } from '../modules/cms/mea-block-icon/mea-block-icon.component';
import { MEABlockImageComponent } from '../modules/cms/mea-block-image/mea-block-image.component';
import { MEABlockTextComponent } from '../modules/cms/mea-block-text/mea-block-text.component';
import { TitreSousTitreComponent } from '../modules/cms/titre-sous-titre/titre-sous-titre.component';
import { UIMEA2Component } from '../modules/cms/uimea2/uimea2.component';
import { UITitleSubtitleComponent } from '../modules/cms/uititle-subtitle/uititle-subtitle.component';
import { interfaces } from '@r3group-digital/common-lib-v2';

@Injectable({
  providedIn: 'root'
})
export class DeserializerService {
  clearComponent(viewContainerRef: ViewContainerRef): void {
    if (viewContainerRef) {
      viewContainerRef.clear();
    }
  }
  clearComponentAndDeserialiseArr(viewContainerRef: ViewContainerRef, data: (interfaces.IVisualPageComponent | interfaces.IVisualComponent)[]): void {
    this.clearComponent(viewContainerRef);
    this.deserialiseArr(viewContainerRef, data);
  }

  deserialiseArr(viewContainerRef: ViewContainerRef, data: (interfaces.IVisualPageComponent | interfaces.IVisualComponent)[]): void {
    data.forEach((obj) => {
      this.deserialise(viewContainerRef, obj);
    });
  }

  clearComponentAndDeserialise(viewContainerRef: ViewContainerRef, data: interfaces.IVisualPageComponent | interfaces.IVisualComponent): void {
    this.clearComponent(viewContainerRef);
    this.deserialise(viewContainerRef, data);
  }

  public compFatory(data: interfaces.IVisualPageComponent | interfaces.IVisualComponent): any {
    let comp: any;
    switch (data._class) {
      case 'Contact':
        comp = ContactComponent;
        break;
      case 'ImgSideBySide':
        comp = ImgSideBySideComponent;
        break;
      case 'HeaderBanner':
        comp = HeaderBannerComponent;
        break;
      case 'TitreSousTitre':
        comp = TitreSousTitreComponent;
        break;
      case 'MEA2':
        comp = MEA2Component;
        break;
      case 'MEA3':
        comp = MEA3Component;
        break;
      case 'MEA_Block_Icon':
        comp = MEABlockIconComponent;
        break;
      case 'MEA_Block_Image':
        comp = MEABlockImageComponent;
        break;
      case 'MEA_Block_Text':
        comp = MEABlockTextComponent;
        break;
      case 'CallToAction':
        comp = CallToActionComponent;
        break;
      case 'UIMEA2':
        comp = UIMEA2Component;
        break;
      case 'UITitleSubtitle':
        comp = UITitleSubtitleComponent;
        break;

      default:
    }
    return comp;
  }
  public deserialise(viewContainerRef: ViewContainerRef, data: interfaces.IVisualPageComponent | interfaces.IVisualComponent): void {
    const comp: any = this.compFatory(data);
    if (!comp) {
      console.warn(`the class ${data._class} is undefined`);
      return;
    }

    const componentRef: any = viewContainerRef.createComponent(comp);
    setTimeout(() => {
      componentRef.instance.data = data;
    }, 50);
  }
}
