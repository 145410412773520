import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiHelper } from 'leandredev_common-ng16/httpClient';
import { BaseComponent, RunTimeDataService } from 'leandredev_common-ng16/infraService';
import { interfaces } from '@r3group-digital/common-lib-v2';
import { filter, switchMap, map, mergeMap } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { DeserializerService } from 'src/app/services/deserializer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit, AfterViewInit {
  public title = 'R3 Group';
  public titleUrl = 'https://www.r3-group.fr/';
  public cgu = '630650933cdd119c2325d911';
  public facebook = 'https://www.facebook.com/R3GroupFrance';
  public instagram = 'https://www.instagram.com/r3group_fr/';
  public legalNotice = '630651bc6bab55e510761cd8';
  public linkedin = 'https://www.linkedin.com/company/r3-impact-france/';
  public twitter = 'https://twitter.com/R3Group1';
  public youtube = 'https://www.youtube.com/channel/UCVrilrvpRCfiqKYrqdl708A';

  protected viewIsOk = false;
  protected hasDeserialiseData = false;
  protected pData: interfaces.ILandingPage | null = null;
  public get data(): interfaces.ILandingPage | null {
    return this.pData;
  }
  public set data(value: interfaces.ILandingPage | null) {
    if (this.pData == value) {
      return;
    }
    this.pData = value;
    if (this.pData) {
      this.hasDeserialiseData = true;
    }
    this.deserialize();
  }
  constructor(
    protected deserializer: DeserializerService,
    protected dataService: DataService,
    protected runTimeDataService: RunTimeDataService,
    private activeRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.runTimeDataService
        .getObservableStore('publicConf')
        .pipe(
          filter((data) => {
            return Boolean(data?.landingId);
          }),
          switchMap((data) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return this.dataService.homeService.getLanding({}, data.landingId);
          }),
          ApiHelper.resultToObjWEmptyError(`no lanfingPage found`),
          map((landingPage: any) => {
            this.data = landingPage;
          }),
          mergeMap(() => {
            return this.activeRoute.queryParams;
          }),
          map((data: any) => {
            if (data.inv && data.userMail) {
              environment.inv = data.inv;
              environment.userMail = data.userMail;
            }
          })
        )
        .subscribe()
    );
  }
  ngAfterViewInit(): void {
    this.viewIsOk = true;
    this.deserialize();
  }
  public deserialize(): void {
    if (this.viewIsOk && this.hasDeserialiseData) {
      try {
        const newFooterData = this.data?.contents?.filter((item) => item._class === 'Footer')[0] as interfaces.IFooter;

        if (newFooterData.title) {
          this.title = newFooterData.title;
        }
        if (newFooterData.titleUrl) {
          this.titleUrl = newFooterData.titleUrl;
        }
        if (newFooterData.facebook) {
          this.facebook = newFooterData.facebook;
        }
        if (newFooterData.instagram) {
          this.instagram = newFooterData.instagram;
        }
        if (newFooterData.twitter) {
          this.twitter = newFooterData.twitter;
        }
        if (newFooterData.linkedin) {
          this.linkedin = newFooterData.linkedin;
        }
        if (newFooterData.youtube) {
          this.youtube = newFooterData.youtube;
        }
        if (newFooterData.legalNotice) {
          this.legalNotice = newFooterData.legalNotice;
        }
        if (newFooterData.cgu) {
          this.cgu = newFooterData.cgu;
        }
      } catch (error) {
        console.warn('not footer data defined', error);
      }
    }
  }
}
