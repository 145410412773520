import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { SignInJwtService } from '../services/sign-in-jwt.service';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsSignedInService {
  constructor(
    private router: Router,
    protected signInService: SignInJwtService
  ) {}

  public canActivate(): Observable<boolean> {
    if (this.signInService.isConnecting) {
      return this.signInService.connectedObs.pipe(
        map((connected) => {
          if (!this.signInService.isConnected) {
            void this.router.navigate(['login/']);
          }
          return connected;
        })
      );
    } else {
      if (!this.signInService.isConnected) {
        void this.router.navigate(['login/']);
      }

      return of(this.signInService.isConnected);
    }
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
export const IsSignedInGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(IsSignedInService).canActivate();
};
